<template lang="pug">
  .top-bar.filters
    .row.filters.align-items-center
      .filter
        AppIconButton.add-new(
          icon="plus-circle"
          title="plan_management.actions.add_new"
          @click="$emit('add-new')"
        )
      .filter
        AppSearch(
          :value="filters.searchValue"
          @update="applyFilter('searchValue', $event)"
        )
      .filter
        AppDatepicker(
          :value="filters.dateRange"
          range
          @change="applyFilter('dateRange', $event)"
        )
      .filter
        AppDropdown(
          allow-empty
          close-on-select
          :value="filters.periodInclusion"
          :items="periodInclusions"
          @select="applyFilter('periodInclusion', $event)"
        )
      .filter
        AppIconButton.get-data-from-ota(
          :disabled="inProgressImports"
          title="plan_management.actions.get_data_from_ota"
          @click=""
          icon="reply"
          :use-app-icon="true"
          v-b-tooltip.hover="$t('inventory_management.tooltip')"
        )
      .filter
        AppSaveButton(
          :disabled="isSaveDisabled"
          short
          title="plan_management.actions.save_changes"
          @save="$emit('save-changes')"
        )
    .row.in-progress-notification(v-if="inProgressImports")
      SyncInProgressNotification(shown)
</template>

<script>
  import { PERIOD_INCLUSION_TYPES } from "./../constants.js"

  // mixins
  import withWebSocket from "@/mixins/withWebSocket"

  // store modules
  import withStoreModule from "@/mixins/withStoreModule"
  import importsModule from "@/config/store/plan_management/imports"

  const importsMixin = withStoreModule(importsModule, {
    name: "planManagementImports",
    readers: { inProgressImports: "inProgressImports" },
    actions: {
      importPlansData: "IMPORT_PLANS_DATA",
      checkInProgressImports: "CHECK_IN_PROGRESS_IMPORTS"
    }
  })

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppButton: () => import("@/components/elements/AppButton"),
      SyncInProgressNotification: () => import("@/pages/InventoryManagement/SyncInProgressNotification.vue")
    },

    mixins: [importsMixin, withWebSocket],

    mounted() {
      this.checkInProgressImports()
      this.webSocketSubscribe("PlanManagementChannel", {
        received: () => this.checkInProgressImports()
      })
    },

    data() {
      return {
        periodInclusions: PERIOD_INCLUSION_TYPES
      }
    },

    props: {
      filters: {
        type: Object,
        default: () => new Object()
      },
      isSaveDisabled: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      applyFilter(itemType, items) {
        const filters = { ...this.filters, [itemType]: items }
        this.$emit("change-filters", filters)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .row
    margin-right: 0

    &.filters
      display: flex
      justify-content: space-between

    &.in-progress-notification
      display: flex
      justify-content: right

      .notification
        margin-right: 0

  .top-bar.filters
    +filter-title
    padding-left: 6px

    .filter
      padding-left: 10px

      .get-data-from-ota
        background-color: $default-purple
        ::v-deep
          .title
            color: $default-white

      .add-new
        background-color: $default-purple
        ::v-deep
          svg
            path
              fill: $default-white
          .title
            color: $default-white
</style>
